<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    装备包详情
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="equipInfo" ref="equipInfo">
                    <el-form-item label="编号" :label-width="formLabelWidth" prop="id">
                        <el-input v-model="equipInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="装备包名称" :label-width="formLabelWidth" prop="bagName">
                        <el-input v-model="equipInfo.bagName" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="工作内容" :label-width="formLabelWidth" prop="workContent">
                        <el-input v-model="equipInfo.workContent" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="工作日期" :label-width="formLabelWidth" prop="workDate">
                        <el-date-picker v-model="equipInfo.workDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期" :readonly="state">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="经办人" :label-width="formLabelWidth" prop="workJbr">
                        <el-input v-model="equipInfo.workJbr" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="装备包状态" :label-width="formLabelWidth" prop="status_text">
                        <el-input v-model="equipInfo.status_text" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                </el-form>
                <h1>装备包仪器仪表</h1>
                <el-divider></el-divider>

                <!-- 仪器列表 -->
                <el-table :data="deviceInfoList" border style="width: 100%"
                    :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                    <el-table-column prop="seq" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                    <el-table-column prop="deviceName" label="仪器名称" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceRealName" label="实际名称" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceCode" label="仪器条码" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceNum" label="仪器编号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceType" label="仪器型号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceStatus_text" label="仪器状态" align="center">
                    </el-table-column>
                    <el-table-column prop="location" label="保存地点" align="center">
                    </el-table-column>
                </el-table>

            </div>

        </el-card>
    </div>
</template>

<script>
import { queryEquipBag } from '@/api/deviceEquipbag.js'
import { deviceStatusList } from '@/api/device.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceStatusList: deviceStatusList,
            deviceInfoList: [],
            equipInfo: {
                id: '',
                bagName: null,
                workContent: null,
                workDate: null,
                workJbr: null,
                deviceContent: null,
                deviceList: [],
                deviceNumber: 0,
                status: '00',
                createTime: null
            },
            formLabelWidth: "100px",
            state: this.id ? true : false,
        }
    },
    mounted() {
        this.loadEquipBagInfo()
    },
    methods: {
        async loadEquipBagInfo() {
            await queryEquipBag(this.id).then(res => {
                if (res.code === '000000') {
                    this.equipInfo = res.t
                    this.equipInfo.status === '00' ? (this.equipInfo.status_text = '正常') : (this.equipInfo.status_text = '失效')
                    this.deviceInfoList = res.t.deviceList
                    let count = 1
                    this.deviceInfoList.forEach(item => {
                        item.seq = count++
                        const index = this.deviceStatusList.findIndex(item2 => item2.code === item.deviceStatus)
                        if (index !== -1) {
                            const { name } = this.deviceStatusList[index]
                            item.deviceStatus_text = name
                        }
                    })
                }
            })
        },

    },
}
</script>
<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 500px;
    }

    .df {
        text-align: right;
    }

    .deviceForm {
        margin-bottom: 10px;

        .deviceSelecct {
            width: 30%;
        }
    }

    .el-table {
        margin-bottom: 30px;
    }
}
</style>